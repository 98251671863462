import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import React, { useEffect, useState } from "react"

export const VCfpP = ({ call, state, close, name = "", password = "",tag='暗証番号名' }) => {
  const [value, setValue] = useState(name)
  const [isNumber, setIsNumber] = useState(false)

  useEffect(()=>{
    if (tag==='暗証番号'){
      setIsNumber(true)
    }else {
      setIsNumber(false)
    }

  },[tag])
  const handleChange = (e) => {
    const inputValue = e.target.value;

    if (isNumber) {
      // 只允许输入数字
      if (/^\d*$/.test(inputValue)) {
        setValue(inputValue);
      }
    } else {
      // 允许输入字母、汉字和数字
      setValue(inputValue);
    }
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="flex-start" sx={{ mt: 0 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" sx={{ fw: "bold" }}>
            {tag}
            <Box component="span" color="red">
              *
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={tag}
            variant="outlined"
            fullWidth
            size="small"
            value={value}
            onChange={handleChange}

          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} sx={{ textAlign: "right" }}>
        <Button
          size="small"
          sx={{ mr: "10px" }}
          onClick={() => {
            close()
          }}
        >
          キャンセル
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            if (call) call(value)
          }}
        >
          確認
        </Button>
      </Grid>
    </Box>
  )
}
export const VCfpC = ({ call, state, close }) => {
  const [value, setValue] = useState(`${state.name}`)
  return (
    <Box>
      <Grid container alignItems="top" spacing={1} sx={{ mt: "2px", p: "0px" }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" sx={{ fw: "bold" }}>
            カード名
            <Box component="span" color="red">
              *
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ justifyContent: "flex-end" }}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} sx={{ textAlign: "right" }}>
          <Button
            size="small"
            sx={{ mr: "10px" }}
            onClick={() => {
              close()
            }}
          >
            キャンセル
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              console.log("要改的名稱", value)
              if (call) call(value)
            }}
          >
            確認
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
export const VCfpN = ({ call, state, close }) => {
  const [value, setValue] = useState(`${state.name}`)
  return (
    <Box>
      <Grid container alignItems="top" spacing={1} sx={{ mt: "2px", p: "0px" }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" sx={{ fw: "bold" }}>
            名称
            <Box component="span" color="red">
              *
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ justifyContent: "flex-end" }}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} sx={{ textAlign: "right" }}>
          <Button
            size="small"
            sx={{ mr: "10px" }}
            onClick={() => {
              close()
            }}
          >
            キャンセル
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              console.log("要改的名稱", value)
              if (call) call(value)
            }}
          >
            確認
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
export const VCfpF = ({ call, state, close }) => {
  const [value, setValue] = useState(`${state.name}`)

  return (
    <Box>
      <Grid container alignItems="top" spacing={1} sx={{ mt: "2px", p: "0px" }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" sx={{ fw: "bold" }}>
            指紋名
            <Box component="span" color="red">
              *
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="指紋名"
            variant="outlined"
            fullWidth
            size="small"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} sx={{ textAlign: "right" }}>
          <Button
            size="small"
            sx={{ mr: "10px" }}
            onClick={() => {
              close()
            }}
          >
            キャンセル
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              console.log("STATE指紋", state)
              if (call) call(value)
            }}
          >
            確認
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
export default function CfpModify({
  type,
  state,
  leftClick,
  sureClick,
  name,
  password,
    tag='暗証番号名'
}) {
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <IconButton size="small" onClick={leftClick}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        {type === "c" && (
          <VCfpC call={sureClick} state={state} close={leftClick} />
        )}
        {type === "f" && (
          <VCfpF call={sureClick} state={state} close={leftClick} />
        )}
        {type === "n" && (
          <VCfpN call={sureClick} state={state} close={leftClick} />
        )}
        {type === "p" && (
          <VCfpP
            call={sureClick}
            state={state}
            close={leftClick}
            password={password}
            name={name}
            tag={tag}
          />
        )}
      </Box>
    </>
  )
}
