import {

  CardContent,
  Chip,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material"
import React from "react"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import EditRoundedIcon from "@mui/icons-material/EditRounded";

export const CfpCmDetails = ({ mdetails, bindMm, bindTouch, editClick,isShowbindMm = true,delBot=undefined}) => {
  const title = {
    width: "180px",
    fontWeight: "bold",
  }
  const infoItem = {
    display: "flex",
    alignItems: "center",
  }



  const handleDeleteChip=(key,i, item)=>{
    if (key==='認証機器'){
      console.log("key",key,item,mdetails)
      if (delBot)delBot(i)
    }

  }

  return (
    <>
      <CardContent>
        <List
          disablePadding
          sx={{
            "> .css-1samsxy-MuiListItem-root": {
              padding: "0px",
            },
          }}
        >
          {Object.entries(mdetails).map(([key, value], index) => (
            <ListItem key={index} sx={{ ...infoItem }}>
              <Typography sx={{ ...title }}>{key}</Typography>
              {Array.isArray(value)
                  ? value.map((item, i) => (
                      <div key={i} style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                        <Chip
                          sx={{ marginRight: '2px' }}
                          label={item}
                          onDelete={value.length > 1 ? () => handleDeleteChip(key, i, value) : undefined}
                        />
                      </div>
                  ))
                  : value ?? ''}

              {key === "ユーザー名" && (( value === "-" ) || isShowbindMm ) && (
                <IconButton onClick={bindMm}>
                  <AddCircleIcon style={{ color: "#28AEB1" }} />
                </IconButton>
              )}

              {key === "認証機器" && (
                <IconButton onClick={bindTouch}>
                  <AddCircleIcon style={{ color: "#28AEB1" }} />
                </IconButton>
              )}
              {key === "暗証番号" && (
                  <IconButton
                      sx={{ ml: '2px' }}
                      size="small"
                      onClick={()=>{
                        if (editClick){

                          editClick(value)
                        }

                      }}
                  >
                    <EditRoundedIcon
                        fontSize="inherit"
                        sx={{ color: 'primary.main', fontSize: '14px' }}
                    />
                  </IconButton>
              )}
            </ListItem>
          ))}
        </List>
      </CardContent>
    </>
  )
}
