import {Box, CardHeader, IconButton, Typography} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import React from "react";


export const CfpBackTitle=({title,clickEdit})=>{




    return <>
        <CardHeader
            title={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        size="small"
                        onClick={() => {
                            window.history.back()
                        }}
                    >
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Typography
                        variant="h2"
                        sx={{
                            ml: '9px',
                        }}
                    >
                        {title ||'未設定' }
                    </Typography>
                    <IconButton
                        sx={{ ml: '2px' }}
                        size="small"
                        onClick={clickEdit}
                    >
                        <EditRoundedIcon
                            fontSize="inherit"
                            sx={{ color: 'primary.main', fontSize: '14px' }}
                        />
                    </IconButton>
                </Box>
            }
        />

    </>
}